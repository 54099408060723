'use client';
import { ReactNode } from 'react';

import { FCC } from '../../fcc';
import { useHasMountedState } from '../../hooks/useHasMounted';

export interface NoSsrProps {
  onSsr?: ReactNode;
}

export const NoSsr: FCC<NoSsrProps> = ({ children, onSsr }) => {
  const mounted = useHasMountedState();

  return mounted ? (
    <>{children}</>
  ) : (
    <>{onSsr === undefined ? <span /> : onSsr}</>
  );
};
