import { useActionHandler } from '@komo-tech/ui/hooks/useActionHandler';
import { useEvent } from '@komo-tech/ui/hooks/useEvent';

import { SiteUser } from '@/common/models/SiteUser';

import { PublicContactsActions } from '../_actions';
import {
  ContactCompetitionEntryVerifyRequest,
  ContactVerifyResponse
} from '../_types';

interface Options {
  onSuccess: (session?: SiteUser) => void;
  onFail: (message: string) => void;
  noToastOnError?: boolean;
}
export function useContactCompetitionEntryVerifyHandleAsync({
  onSuccess,
  onFail,
  noToastOnError
}: Options) {
  const [handleAsync, { isHandling }] = useActionHandler();

  const handleCheckAsync = useEvent(
    (request: ContactCompetitionEntryVerifyRequest) =>
      handleAsync(
        () => PublicContactsActions.verifyCompetitionEntryAsync(request),
        {
          noToastOnError,
          onSuccess: (response: ContactVerifyResponse) => {
            if (response.success) {
              onSuccess(new SiteUser(response.session));
            } else {
              onFail(response.errorMessage ?? 'Code is invalid or expired');
            }
          },
          onError: (err) => {
            console.error(err);
            onFail('Code is invalid or expired');
          }
        }
      )
  );

  return [handleCheckAsync, isHandling] as const;
}
