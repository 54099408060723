import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { asNumber } from '@komo-tech/core/utils/number';
import { immerable } from 'immer';

export class FrontBadge {
  [immerable] = true;
  id: Guid;
  achievementId: Guid;
  title: string;
  image: ImageDataModel;
  description: string;
  order: number;
  earned: boolean = false;

  constructor(props?: Partial<FrontBadge>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrUndefined(props.id);
    this.achievementId = Guid.valueOrUndefined(props.achievementId);
    this.image = ImageDataModel.fromJsonOrUrl(props.image);
    this.order = asNumber(props.order, 1);
  }

  awardBadge() {
    this.earned = true;
  }
}
