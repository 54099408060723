import { CustomItemViewerDefinition } from '@/common/components/BlockBuilder/Items/Custom';

import { BlockContactCustomTypes as CustomTypes } from '../../BlockContactCustomTypes';
import { BlockContactBadgesItemResolver as Resolver } from '../BlockContactBadgesItemResolver';
import { BlockContactBadgesItemViewRenderer as ItemRenderer } from './_Renderer';

export const BlockContactBadgesItemViewDefinition: CustomItemViewerDefinition =
  {
    customType: CustomTypes.ContactBadges,
    render: () => <ItemRenderer />,
    resolver: Resolver
  };
