import { FCC } from 'fcc';
import { memo } from 'react';

import { BlockImageItemViewRenderer } from '@/common/components/BlockBuilder/Items/Image';
import { useBlockViewerItem } from '@/common/components/BlockBuilder/Viewer/Context/BlockViewerItemProvider';

import { useContactPropertyAvatarDataSource } from '../../DataSources';
import { BlockContactAvatarItem } from '../BlockContactAvatarItem';

export const BlockContactAvatarItemViewRenderer: FCC = memo(() => {
  const item = useBlockViewerItem((x) => x.item) as BlockContactAvatarItem;
  const { propertyAvatar, isLoading } = useContactPropertyAvatarDataSource(
    item.dataSourceInstance,
    item.getData().contactPropertyId
  );

  if (!propertyAvatar) {
    return null;
  }

  return (
    <BlockImageItemViewRenderer
      overrideImageLoading={isLoading}
      overrideImage={propertyAvatar}
    />
  );
});
