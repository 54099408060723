import { Guid } from '@komo-tech/core/models/Guid';

import { FrontContactAvatar } from '@/common/models/contact-avatars/Front/FrontContactAvatar';
import { FrontContactPropertyDefinition } from '@/common/models/contacts/Front/FrontContactPropertyDefinition';

export class FrontContactPropertyValue {
  stringValue?: string;
  numberValue?: number;
  dateTimeValue?: Date;
  contactAvatar?: FrontContactAvatar;
  propertyDefinitionId: Guid;
  propertyDefinitionType: string;
  achievementId?: Guid;
  propertyDefinition: FrontContactPropertyDefinition;

  constructor(props?: Partial<FrontContactPropertyValue>) {
    props = props || {};
    Object.assign(this, props);
    this.propertyDefinitionId = Guid.valueOrUndefined(
      props.propertyDefinitionId
    );
    if (props.contactAvatar) {
      this.contactAvatar = new FrontContactAvatar(props.contactAvatar);
    }
    this.achievementId = Guid.valueOrUndefined(props.achievementId);
    if (props.propertyDefinition) {
      this.propertyDefinition = new FrontContactPropertyDefinition(
        props.propertyDefinition
      );
    }
  }

  get displayValue() {
    if (this.stringValue != undefined) {
      return this.stringValue;
    }
    if (this.numberValue != undefined) {
      return this.numberValue.toLocaleString();
    }
    if (this.dateTimeValue != undefined) {
      return this.dateTimeValue.toLocaleDateString();
    }
    return '';
  }
}
