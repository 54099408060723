import { FC } from 'react';

import { BlockBackgroundRenderer } from '@/common/components/BlockBuilder/shared/BlockBackgroundRenderer';
import {
  useBlockViewer,
  useBlockViewerItem
} from '@/common/components/BlockBuilder/Viewer/Context';
import { Image } from '@/common/components/Image';

import { useContactBadgesDataSource } from '../../DataSources';
import { BlockContactBadgesItem as Item } from '../BlockContactBadgesItem';

export const BlockContactBadgesItemViewRenderer: FC = () => {
  const registerElement = useBlockViewerItem((x) => x.registerElement);
  const item = useBlockViewerItem((x) => x.item) as Item;
  const stageScale = useBlockViewer((x) => x.stageScale);

  const { containerStyle, data, containerKey } = item.getRenderData(stageScale);
  const shownItems = data.rows * data.columns;

  const { items, isLoading } = useContactBadgesDataSource({
    dataSource: item.dataSourceInstance,
    shownItems,
    itemId: item.id
  });

  const renderedItems =
    items?.filter((x) => !!x.image)?.slice(0, shownItems) || [];

  return (
    <div
      {...item.getElementProps('Item').dataAttributes}
      style={containerStyle}
      ref={registerElement}
      key={containerKey}
    >
      <BlockBackgroundRenderer key={'bg'} background={data.background} />
      {!isLoading
        ? renderedItems.map((badge) => {
            if (!badge) return;
            const viewData = badge.isEarned();
            return (
              <Image
                key={badge.id.toString()}
                image={viewData.styling.image}
                style={{
                  objectFit: 'contain',
                  filter:
                    !viewData.earned && !badge.disableGrayscale
                      ? 'grayscale(1)'
                      : 'grayscale(0)'
                }}
              />
            );
          })
        : null}
    </div>
  );
};
