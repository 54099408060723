import { IBlockItem } from '@/common/components/BlockBuilder/types/IBlockItem';

import { BlockContactAvatarItemResolver as AvatarResolver } from './AvatarItem';
import { BlockContactBadgesItemResolver as BadgesResolver } from './BadgesItem';
import { BlockContactPropertyTextItemResolver as PropertyTextResolver } from './PropertyTextItem';

const resolvers = [PropertyTextResolver, AvatarResolver, BadgesResolver];

export const BlockContactItemsResolver = (item: IBlockItem) => {
  for (let i = 0; i < resolvers.length; i++) {
    const resolved = resolvers[i](item);
    if (resolved) {
      return resolved;
    }
  }

  return undefined;
};
