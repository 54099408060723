import { useContactEmailCheckHandleAsync } from './_useEmailCheck';
import { useContactEmailVerifyHandleAsync } from './_useEmailVerify';
import { useContactCompetitionEntryCheckHandleAsync } from './_useEntryCheck.ts';
import { useContactCompetitionEntryVerifyHandleAsync } from './_useEntryVerify';
import { useSearchAvatarsQuery } from './_useSearchAvatarsQuery';

export const PublicContactsHooks = {
  avatars: { useSearch: useSearchAvatarsQuery },
  useEmailCheckHandle: useContactEmailCheckHandleAsync,
  useEmailVerifyHandle: useContactEmailVerifyHandleAsync,
  useEntryCheckHandle: useContactCompetitionEntryCheckHandleAsync,
  useEntryVerifyHandle: useContactCompetitionEntryVerifyHandleAsync
};
