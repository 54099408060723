import { IBlockItem } from '@/common/components/BlockBuilder/types/IBlockItem';

import { BlockContactPropertyTextItem as Item } from './BlockContactPropertyTextItem';

export const BlockContactPropertyTextItemResolver = (item: IBlockItem) => {
  if (item.customType === Item.CustomType) {
    return new Item(item as Item);
  }

  return undefined;
};
