import { FCC } from 'fcc';
import { memo } from 'react';

import { BlockTextItemViewRenderer } from '@/common/components/BlockBuilder/Items/Text';
import { useBlockViewerItem } from '@/common/components/BlockBuilder/Viewer/Context/BlockViewerItemProvider';

import { useContactPropertyTextDataSource } from '../../DataSources';
import { BlockContactPropertyTextItem as Item } from '../BlockContactPropertyTextItem';

export const BlockContactPropertyTextItemViewRenderer: FCC = memo(() => {
  const item = useBlockViewerItem((x) => x.item) as Item;
  const { propertyText, isLoading } = useContactPropertyTextDataSource(
    item.dataSourceInstance,
    item.getData().contactPropertyId
  );
  return (
    <BlockTextItemViewRenderer
      overrideTextLoading={isLoading}
      overrideText={propertyText}
    />
  );
});
