import { mapArray } from '@komo-tech/core/utils/array';

import { FrontContactAvatar } from '@/common/models/contact-avatars/Front/FrontContactAvatar';

import { FrontContactPropertyValue } from './FrontContactPropertyValue';

export class FrontContactProperties {
  propertyValues: FrontContactPropertyValue[];
  defaultContactAvatar: FrontContactAvatar;

  constructor(props?: Partial<FrontContactProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.propertyValues = mapArray(
      props.propertyValues || [],
      (x) => new FrontContactPropertyValue(x)
    );
    this.defaultContactAvatar = new FrontContactAvatar(
      props.defaultContactAvatar
    );
  }
}
