import { CustomItemViewerDefinition } from '@/common/components/BlockBuilder/Items/Custom/types';

import { BlockContactCustomTypes as CustomTypes } from '../../BlockContactCustomTypes';
import { BlockContactAvatarItemResolver as Resolver } from '../BlockContactAvatarItemResolver';
import { BlockContactAvatarItemViewRenderer as ItemRenderer } from './_Renderer';

export const BlockContactAvatarItemViewDefinition: CustomItemViewerDefinition =
  {
    customType: CustomTypes.ContactAvatar,
    render: () => <ItemRenderer />,
    resolver: Resolver
  };
