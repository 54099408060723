import { Guid } from '@komo-tech/core/models/Guid';

export class FrontContact {
  id: Guid;

  constructor(props?: Partial<FrontContact>) {
    props = props || {};
    this.id = Guid.valueOrUndefined(props.id);
  }
}
