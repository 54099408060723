import { CustomItemViewerDefinition } from '@/common/components/BlockBuilder/Items/Custom/types';

import { BlockContactCustomTypes as CustomTypes } from '../../BlockContactCustomTypes';
import { BlockContactPropertyTextItemResolver as Resolver } from '../BlockContactPropertyTextItemResolver';
import { BlockContactPropertyTextItemViewRenderer as ItemRenderer } from './_Renderer';

export const BlockContactPropertyTextItemViewDefinition: CustomItemViewerDefinition =
  {
    customType: CustomTypes.ContactPropertyText,
    render: () => <ItemRenderer />,
    resolver: Resolver
  };
