import { useQuery } from '@tanstack/react-query';

import { DataSourceInstance } from '@/common/components/BlockBuilder/DataSource/DataSourceInstance';
import { BlockContactBadgesDataSourceInstance } from '@/common/components/Contacts/BlockBuilder/DataSources/BlockContactBadgesDataSourceInstance';
import { PublicContactsService } from '@/front/data/Contacts';
import { FrontBadgeView } from '@/front/models/FrontBadgeView';

//todo clean up props
interface Props {
  dataSource?: DataSourceInstance;
  shownItems: number;
  itemId: string;
}
export const useContactBadgesDataSource = ({
  dataSource
}: Props): ContactBadgesDataSourceResponse => {
  const contactBadgesDataSource =
    BlockContactBadgesDataSourceInstance.isImplementedBy(dataSource)
      ? dataSource
      : undefined;
  const enabled = contactBadgesDataSource?.isValid();

  const siteId = !!contactBadgesDataSource?.isPortal
    ? contactBadgesDataSource?.siteId
    : undefined;

  const frontBadgesQuery = useQuery(
    PublicContactsService.cacheKeys.achievements.allBadges(siteId),
    async () => {
      const badges =
        await PublicContactsService.actions.achievements.getAllBadgesAsync(
          siteId
        );
      if (!!contactBadgesDataSource && !contactBadgesDataSource.isPortal) {
        const achievementIds =
          await PublicContactsService.actions.achievements.getMineAsync();
        if (achievementIds.length > 0) {
          badges.forEach((badgeView) => {
            badgeView.tryAwardBadge(achievementIds);
          });
        }
      }
      return badges;
    },
    {
      enabled,
      refetchOnMount: !contactBadgesDataSource?.isServerGenerated,
      refetchOnWindowFocus: false
    }
  );

  return {
    isLoading: frontBadgesQuery.isLoading,
    isError: frontBadgesQuery.isError,
    items: frontBadgesQuery.data
  };
};

export interface ContactBadgesDataSourceResponse {
  items: FrontBadgeView[];
  isLoading: boolean;
  isError: boolean;
}
