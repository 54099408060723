import { Box } from '@komo-tech/ui/Box';
import { ErrorBoundary } from '@komo-tech/ui/ErrorBoundary';
import { useClampedSize } from '@komo-tech/ui/hooks/useClampedSize';
import { RectReadOnly, useMeasure } from '@komo-tech/ui/hooks/useMeasure';
import { FC, MutableRefObject } from 'react';

import { DataSourceInstance } from '@/common/components/BlockBuilder/DataSource/DataSourceInstance';
import {
  DataSourceProvider,
  useDataSourceInstances
} from '@/common/components/BlockBuilder/DataSource/DataSourceProvider';
import {
  BlockViewerProvider,
  BlockViewerProviderProps
} from '@/common/components/BlockBuilder/Viewer/Context';
import { ImagePreloaderProvider } from '@/common/components/Image';

import { BlockViewerStage } from '../../BlockViewerStage';
import {
  BlockViewerSingleStageHostProvider,
  BlockViewerSingleStageHostProviderProps,
  useBlockSingleStageView
} from './_Provider';

interface Props
  extends Pick<BlockViewerProviderProps, 'onStageElementRegister'>,
    BlockViewerSingleStageHostProviderProps {
  dataSourcesRef: MutableRefObject<DataSourceInstance[]>;
}

export const BlockViewerSingleStageHost: FC<Props> = ({
  definitions,
  stage,
  utmMedium,
  dataSourcesRef,
  onStageElementRegister
}) => {
  return (
    <ImagePreloaderProvider>
      <DataSourceProvider dataSourcesRef={dataSourcesRef}>
        <BlockViewerSingleStageHostProvider
          definitions={definitions}
          stage={stage}
          utmMedium={utmMedium}
        >
          <_Host onStageElementRegister={onStageElementRegister} />
        </BlockViewerSingleStageHostProvider>
      </DataSourceProvider>
    </ImagePreloaderProvider>
  );
};

interface _HostProps extends Pick<Props, 'onStageElementRegister'> {}

const _Host: FC<_HostProps> = ({ onStageElementRegister }) => {
  const container = useMeasure();

  return (
    <Box ref={container.register} h="100%" w="100%">
      {container.data.hasBounds && (
        <_Stage
          containerBounds={container.data.bounds}
          onStageElementRegister={onStageElementRegister}
        />
      )}
    </Box>
  );
};

interface _StageProps extends Pick<Props, 'onStageElementRegister'> {
  containerBounds: RectReadOnly;
}

const _Stage: FC<_StageProps> = ({
  containerBounds,
  onStageElementRegister
}) => {
  const stage = useBlockSingleStageView((x) => x.stage);
  const customItemDefinitions = useBlockSingleStageView(
    (x) => x.customItemDefinitions
  );
  const utmMedium = useBlockSingleStageView((x) => x.utmMedium);
  const clampedRatio = useClampedSize({
    maxWidth: containerBounds.width,
    maxHeightFunc: () => containerBounds.height,
    aspectRatio: stage.data.size.tryGetAspectRatio()
  });
  const dataSources = useDataSourceInstances();

  return (
    <ErrorBoundary fallback={<></>}>
      <BlockViewerProvider
        stage={stage}
        stageUtmMedium={utmMedium}
        itemResolver={stage.itemResolver}
        onStageElementRegister={onStageElementRegister}
        customItemDefinitions={customItemDefinitions}
        dataSourceInstances={dataSources}
      >
        <BlockViewerStage clampedSize={clampedRatio} />
      </BlockViewerProvider>
    </ErrorBoundary>
  );
};
