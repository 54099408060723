import { Guid } from '@komo-tech/core/models/Guid';

import { SearchAvatarsQuery, SearchPropertyDefinitionsRequest } from './_types';

const baseKey = 'PublicContacts';
const baseAchievementsKey = `${baseKey}_Achievements`;
const baseProperties = `${baseKey}_Properties`;
const baseAvatars = `${baseKey}_Avatars`;

export const PublicContactsCacheKeys = {
  achievements: {
    allBadges: (siteId?: Guid) => [
      baseAchievementsKey,
      'All',
      siteId?.toString()
    ],
    myBadges: () => [baseAchievementsKey, 'Mine'],
    badgeAlbumBlocks: () => [baseAchievementsKey, 'BadgeAlbumBlocks'],
    getBadgeAlbumImage: () => [baseAchievementsKey, 'BadgeAlbumImage']
  },
  properties: {
    values: () => [baseProperties, 'Values'],
    searchDefinitions: (request: SearchPropertyDefinitionsRequest) => [
      baseProperties,
      'Definitions',
      request
    ]
  },
  avatars: {
    search: (x: SearchAvatarsQuery) => [baseAvatars, 'Search', { ...x }]
  }
};
